import React, { useState, useEffect } from "react";
import Heading from "carbon-react/lib/components/heading";
import { Row, Column } from "carbon-react/lib/components/row";
import Help from "carbon-react/lib/components/help";
import Textbox from "carbon-react/lib/__experimental__/components/textbox";
import Button from "carbon-react/lib/components/button";
import CreateInstitutionDialog from "./CreateInstitutionDialog";
import InstitutionsTable from "./InstitutionsTable";
import { useDirectoryApi } from "../../services/DirectoryApi";
import appConfig from "../../config/appConfig";

import "./Institutions.css";

const Institutions = () => {
  const { listInstitutions } = useDirectoryApi();
  const [institutionsFilter, setInstitutionsFilter] = useState("");
  const [institutionsResultSet, setInstitutionsResultSet] = useState({});
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  useEffect(() => {
    getInstitutions();
  }, []); // eslint-disable-line

  const getInstitutions = async () => {
    //TODO: RB - Add filters
    const institutionsFilters = {
      skip: 0,
      top: 20,
      filter: institutionsFilter ===''? '' : `name like ${institutionsFilter}`,
      orderBy: "name asc",
    };
    const institutionsResponse = await listInstitutions(institutionsFilters);
    
    //TODO: RB - handle errors
    if (!institutionsResponse.success) throw new Error();

    setInstitutionsResultSet(institutionsResponse.data);
  };

  return (
    <>
      <Row className="headingRow">
        <Column>
          <Heading title="Institutions" divider={false} />
        </Column>
      </Row>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          getInstitutions();
        }}
      >
        <Row className="actionRow">
          <Column>
            <Textbox
              value={institutionsFilter}
              placeholder="name like Amazon"
              onChange={(e) => {
                setInstitutionsFilter(e.target.value);
              }}
            />
          </Column>
          <Column>
            <Button buttonType="primary" type="submit">
              Search
            </Button>
            <Help
              className="searchHelpIcon"
              href={appConfig.institutionsListDocumentationUrl}
            >
              <p>Example: "name like 'Amaz'"</p>
            </Help>
          </Column>
          <Column columnAlign="right">
            <Button
              onClick={() => {
                setAddDialogOpen(true);
              }}
              buttonType="primary"
            >
              Add Institution
            </Button>
          </Column>
        </Row>
      </form>
      <InstitutionsTable
        institutionsResultSet={institutionsResultSet}
        onDeleteInstitution={async () => {
          await getInstitutions();
        }}
      />
      <CreateInstitutionDialog
        isOpen={addDialogOpen}
        onSave={async () => {
          setAddDialogOpen(false);
          await getInstitutions();
        }}
        onCancel={() => {
          setAddDialogOpen(false);
        }}
      />
    </>
  );
};

export default Institutions;
