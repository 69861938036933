import React, { useState } from "react";
import {
  FlatTable,
  FlatTableHead,
  FlatTableBody,
  FlatTableRow,
  FlatTableHeader,
  FlatTableCell,
} from "carbon-react/lib/components/flat-table";
import {
  ActionPopover,
  ActionPopoverItem,
} from "carbon-react/lib/components/action-popover";
import Confirm from "carbon-react/lib/components/confirm";
import { useDirectoryApi } from "../../services/DirectoryApi";
import ViewInstitutionDialog from "./ViewInstitutionDialog";
import { useToaster } from "../../contexts/ToasterContext";

import "./InstitutionsTable.css";

const InstitutionsTable = ({ institutionsResultSet, onDeleteInstitution }) => {
  const { deleteInstitution } = useDirectoryApi();
  const { toastSuccess, toastError } = useToaster();
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);

  if (!institutionsResultSet || !institutionsResultSet.data) return null;

  const openViewDialog = (institution) => {
    setSelectedInstitution(institution);
    setViewDialogOpen(true);
  };

  const performDeleteInstitution = async () => {
    try {
      const response = await deleteInstitution(selectedInstitution.id);

      if (!response.success) {
        toastError(`Failed to delete institution ${selectedInstitution.name}.`);
        return;
      }

      await onDeleteInstitution();
      setConfirmDeleteOpen(false);

      toastSuccess(
        `Institution ${selectedInstitution.name} deleted successfully.`
      );
    } catch (error) {
      console.log(error);
    }
  };

  const tableRows = () => {
    return institutionsResultSet.data.map((institution, key) => {
      return (
        <FlatTableRow
          onClick={() => {
            openViewDialog(institution);
          }}
          id={`institutions-table-row-${key}`}
          key={institution.id}
        >
          <FlatTableCell id={`institutions-table-cell-name-${key}`}>
            {institution.name}
          </FlatTableCell>
          <FlatTableCell id={`institutions-table-cell-country-${key}`}>
            {institution.country}
          </FlatTableCell>
          <FlatTableCell id={`institutions-table-cell-officialref-${key}`}>
            {institution.officialRef}
          </FlatTableCell>
          <FlatTableCell id={`institutions-table-cell-officialtaxref-${key}`}>
            {institution.officialTaxRef}
          </FlatTableCell>
          <FlatTableCell id={`institutions-table-cell-action-${key}`}>
            <ActionPopover>
              <ActionPopoverItem
                onClick={() => {
                  openViewDialog(institution);
                }}
                icon="view"
              >
                View
              </ActionPopoverItem>
              <ActionPopoverItem
                onClick={() => {
                  setSelectedInstitution(institution);
                  setConfirmDeleteOpen(true);
                }}
                icon="bin"
              >
                Delete
              </ActionPopoverItem>
            </ActionPopover>
          </FlatTableCell>
        </FlatTableRow>
      );
    });
  };

  return (
    <>
      <FlatTable className="institutionsTable">
        <FlatTableHead>
          <FlatTableRow>
            <FlatTableHeader>Name</FlatTableHeader>
            <FlatTableHeader>Country</FlatTableHeader>
            <FlatTableHeader>Official Reference</FlatTableHeader>
            <FlatTableHeader>Official Tax Reference</FlatTableHeader>
            <FlatTableHeader></FlatTableHeader>
          </FlatTableRow>
        </FlatTableHead>
        <FlatTableBody>{tableRows()}</FlatTableBody>
      </FlatTable>
      <Confirm
        title="Delete Institution"
        open={confirmDeleteOpen}
        onConfirm={performDeleteInstitution}
        onCancel={() => setConfirmDeleteOpen(false)}
        size="medium-small"
      >
        Are you sure you want to delete this institution?
      </Confirm>
      <ViewInstitutionDialog
        institution={selectedInstitution}
        isOpen={viewDialogOpen}
        onCancel={() => setViewDialogOpen(false)}
      />
    </>
  );
};

export default InstitutionsTable;
