import React, { useState, useEffect } from "react";
import Heading from "carbon-react/lib/components/heading";
import { Row, Column } from "carbon-react/lib/components/row";
import Help from "carbon-react/lib/components/help";
import Textbox from "carbon-react/lib/__experimental__/components/textbox";
import Button from "carbon-react/lib/components/button";
import { useDirectoryApi } from "../../services/DirectoryApi";
import appConfig from "../../config/appConfig";
import apiConfig from "../../config/apiConfig";

import "./Companies.css";
import CompaniesTable from "./CompaniesTable";

const Companies = () => {
  const { listCompanies, getNextPageResults } = useDirectoryApi();
  const [companiesFilter, setCompaniesFilter] = useState("");
  const [companiesResultSet, setCompaniesResultSet] = useState({});
  const [nextPageUrl, setNextPageUrl] = useState("");
  const [nextPageDisabled, setNextPageDisabled] = useState(false);
  const [previousPages, setPreviousPages] = useState([]);
  const [previousPageDisabled, setPreviousPageDisabled] = useState(true);

  useEffect(() => {
    getCompanies();
  }, []); // eslint-disable-line

  const getCompanies = async () => {
    //TODO: SG - Add filters
    const companiesFilters = {
      skip: 0,
      top: 20,
      filter: companiesFilter === "" ? "" : `name like ${companiesFilter}`,
      orderBy: "name asc",
    };
    const companiesResponse = await listCompanies(companiesFilters);
    const previousPages = [
      `${apiConfig.baseUrl}/companies?skip=${companiesFilters.skip}&top=${companiesFilters.top}&filter=${companiesFilters.filter}&orderBy=${companiesFilters.orderBy}`, //TODO: try to get this from some common place
    ];
    setPreviousPages(previousPages);
    showCompaniesResponse(companiesResponse);
  };

  const showCompaniesResponse = (companiesResponse) => {
    //TODO: RB - handle errors
    if (!companiesResponse.success) throw new Error();
    if (companiesResponse.data.pagination.next !== undefined) {
      setNextPageUrl(companiesResponse.data.pagination.next);
      setNextPageDisabled(false);
    } else {
      setNextPageDisabled(true);
    }
    setCompaniesResultSet(companiesResponse.data);
  };

  const updatePreviousPageDisabled = () => {
    if (previousPages.length > 1) {
      setPreviousPageDisabled(false);
    } else {
      setPreviousPageDisabled(true);
    }
  };

  return (
    <>
      <Row className="headingRow">
        <Column>
          <Heading title="Companies" divider={false} />
        </Column>
      </Row>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          previousPages.splice(0, previousPages.length);
          updatePreviousPageDisabled();
          getCompanies();
        }}
      >
        <Row className="actionRow">
          <Column>
            <Textbox
              value={companiesFilter}
              placeholder="name like Amazon"
              onChange={(e) => {
                setCompaniesFilter(e.target.value);
              }}
            />
          </Column>
          <Column>
            <Button buttonType="primary" type="submit">
              Search
            </Button>
            <Help
              className="searchHelpIcon"
              href={appConfig.institutionsListDocumentationUrl}
            >
              <p>Example: "name like 'Amaz'"</p>
            </Help>
          </Column>
        </Row>
      </form>
      <CompaniesTable
        companiesResultSet={companiesResultSet}
        onDeleteCompany={async () => {
          await getCompanies();
        }}
      />
      <Row className="paginationRow">
        <Column columnAlign="right">
          <Button
            onClick={async () => {
              const previousPageUrl = previousPages[previousPages.length - 2];
              if (previousPages.length > 1) {
                previousPages.pop();
              }
              setPreviousPages(previousPages);
              updatePreviousPageDisabled();

              if (previousPageUrl !== undefined) {
                const companiesResponse = await getNextPageResults(
                  previousPageUrl
                );
                showCompaniesResponse(companiesResponse);
              }
            }}
            buttonType="primary"
            disabled={previousPageDisabled}
            className="paginationButtons"
          >
            Previous
          </Button>
          <Button
            onClick={async () => {
              const companiesResponse = await getNextPageResults(nextPageUrl);
              if (!previousPages.includes(nextPageUrl)) {
                previousPages.push(nextPageUrl);
              }
              setPreviousPages(previousPages);
              updatePreviousPageDisabled();
              showCompaniesResponse(companiesResponse);
            }}
            buttonType="primary"
            disabled={nextPageDisabled}
            className="paginationButtons"
          >
            Next
          </Button>
        </Column>
      </Row>
    </>
  );
};

export default Companies;
