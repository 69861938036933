import React from "react";
import Dialog from "carbon-react/lib/components/dialog";
import Textbox from "carbon-react/lib/__experimental__/components/textbox";

const ViewCompanyDialog = ({ company, isOpen, onCancel }) => {
  if (!company) return null;

  return (
    <Dialog open={isOpen} onCancel={onCancel} title={company.name}>
      <Textbox label="Id" value={company.id} disabled={true} />
      <Textbox label="CompanyId" value={company.companyId} disabled={true} />
      <Textbox label="ServiceId" value={company.serviceId} disabled={true} />
      <Textbox label="Kind" value={company.kind} disabled={true} />
      <Textbox label="Country" value={company.country} disabled={true} />
      <Textbox
        label="Official Ref"
        value={company.officialRef}
        disabled={true}
      />
      <Textbox
        label="Official Tax Ref"
        value={company.officialTaxRef}
        disabled={true}
      />
      <Textbox
        label="Last Modified Date"
        value={company.modifiedDateTime}
        disabled={true}
      />
    </Dialog>
  );
};

export default ViewCompanyDialog;
