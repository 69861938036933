import React, { useEffect, useState } from "react";
import Heading from "carbon-react/lib/components/heading";
import { Row, Column } from "carbon-react/lib/components/row";
import Textbox from "carbon-react/lib/__experimental__/components/textbox";
import SearchInstitutionTable from "./SearchInstitutionTable";
import { useDirectoryApi } from "../../services/DirectoryApi";
import TimeTakenForSearch from "../shared/TimeTakenForSearch";
import Button from "carbon-react/lib/components/button";
import PageSizeDropdown from "../shared/PageSizeDropdown";

const SearchInstitutions = () => {
  const { searchInstitutions } = useDirectoryApi();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResultSet, setSearchResultSet] = useState({});
  const [timeTakenToSearchInstitutions, setTimeTakenToSearchInstitutions] =
    useState("");
  const [pageSize, setPageSize] = useState(20);

  const performSearch = async () => {
    //TODO: RB - Add filters
    const searchFilters = {
      skip: 0,
      top: pageSize === "" || pageSize === undefined ? 20 : parseInt(pageSize),
      query: searchQuery,
      filter: "country like GB",
    };
    if (searchQuery.length > 2) {
      const timeBeforeApiCall = Date.now();
      const searchResponse = await searchInstitutions(searchFilters);
      const timeAfterApiCall = Date.now();
      setTimeTakenToSearchInstitutions(timeAfterApiCall - timeBeforeApiCall);
      if (!searchResponse.success) throw new Error();

      setSearchResultSet(searchResponse.data);
    }

    //TODO: RB - handle errors
  };

  useEffect(() => {
    performSearch();
  }, [searchQuery, pageSize]);

  return (
    <>
      <Row className="headingRow">
        <Column>
          <Heading title="Search Institutions" divider={false} />
        </Column>
      </Row>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          performSearch();
        }}
      >
        <Row className="actionsRow">
          <Column>
            <Textbox
              value={searchQuery}
              placeholder="Amazo"
              onChange={async (e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </Column>
          <Column className="page-size-col">
            <div className="directory-label">Page size:</div>
            <PageSizeDropdown
              value={pageSize}
              setValue={setPageSize}
            ></PageSizeDropdown>
            <Button buttonType="primary" type="submit">
              Refresh
            </Button>
          </Column>
          <Column columnAlign="right">
            <TimeTakenForSearch
              searchExecutionTime={timeTakenToSearchInstitutions}
            ></TimeTakenForSearch>
          </Column>
        </Row>
      </form>
      <SearchInstitutionTable searchResultSet={searchResultSet} />
    </>
  );
};

export default SearchInstitutions;
