import React, { useState } from "react";
import {
  FlatTable,
  FlatTableHead,
  FlatTableBody,
  FlatTableRow,
  FlatTableHeader,
  FlatTableCell,
} from "carbon-react/lib/components/flat-table";
import ViewInstitutionDialog from "../institutions/ViewInstitutionDialog";

const SearchInstitutionTable = ({ searchResultSet }) => {
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const onViewDialogCancel = () => {
    setViewDialogOpen(false);
  };

  const onRowSelected = (item) => {
    setSelectedItem(item);
    setViewDialogOpen(true);
  };

  const tableRows = () => {
    if (
      !searchResultSet ||
      !searchResultSet.data ||
      searchResultSet.data.length === 0
    ) {
      if (
        searchResultSet.data !== undefined &&
        searchResultSet.data.length === 0
      ) {
        return (
          <FlatTableRow>
            <FlatTableCell colspan="5">No results found</FlatTableCell>
          </FlatTableRow>
        );
      } else {
        return null; //don't show No results found message on initial load
      }
    }
    return searchResultSet.data.map((searchResult, key) => {
      return (
        <FlatTableRow
          onClick={() => {
            onRowSelected(searchResult);
          }}
          id={`search-table-row-${key}`}
          key={searchResult.id}
        >
          <FlatTableCell id={`search-table-cell-name-${key}`}>
            {searchResult.name}
          </FlatTableCell>
          <FlatTableCell id={`search-table-cell-country-${key}`}>
            {searchResult.country}
          </FlatTableCell>
          <FlatTableCell id={`search-table-cell-officialref-${key}`}>
            {searchResult.officialRef}
          </FlatTableCell>
          <FlatTableCell id={`search-table-cell-officialtaxref-${key}`}>
            {searchResult.officialTaxRef}
          </FlatTableCell>
          <FlatTableCell id={`search-table-cell-score-${key}`}>
            {searchResult._score}
          </FlatTableCell>
        </FlatTableRow>
      );
    });
  };

  return (
    <>
      <FlatTable className="search-table">
        <FlatTableHead>
          <FlatTableRow>
            <FlatTableHeader>Name</FlatTableHeader>
            <FlatTableHeader>Country</FlatTableHeader>
            <FlatTableHeader>Official Reference</FlatTableHeader>
            <FlatTableHeader>Official Tax Reference</FlatTableHeader>
            <FlatTableHeader>Elasticsearch Score</FlatTableHeader>
          </FlatTableRow>
        </FlatTableHead>
        <FlatTableBody>{tableRows()}</FlatTableBody>
      </FlatTable>
      <ViewInstitutionDialog
        institution={selectedItem}
        isOpen={viewDialogOpen}
        onCancel={onViewDialogCancel}
      />
    </>
  );
};

export default SearchInstitutionTable;
