import React from "react";
import CarbonNavigationBar from "carbon-react/lib/components/navigation-bar";
import { Menu, MenuItem } from "carbon-react/lib/components/menu";
import { Link, useLocation } from "react-router-dom";
import appRoutes from "../../config/appRoutes";

const NavigationBar = () => {
  const location = useLocation();
  return (
    <CarbonNavigationBar pl={150} pr={150}>
      <Menu>
        <MenuItem
          {...(location &&
          (location.pathname === appRoutes.institutions ||
            location.pathname.startsWith(appRoutes.institutions))
            ? { selected: true }
            : {})}
        >
          <Link id="navbarLink_institutions" to={appRoutes.institutions}>
            Institutions
          </Link>
        </MenuItem>
        <MenuItem
          {...(location &&
          (location.pathname === appRoutes.searchInstitutions ||
            location.pathname.startsWith(appRoutes.searchInstitutions))
            ? { selected: true }
            : {})}
        >
          <Link
            id="navbarLink_search_institutions"
            to={appRoutes.searchInstitutions}
          >
            Search Institutions
          </Link>
        </MenuItem>
        <MenuItem
          {...(location &&
          (location.pathname === appRoutes.companies ||
            location.pathname.startsWith(appRoutes.companies))
            ? { selected: true }
            : {})}
        >
          <Link id="navbarLink_companies" to={appRoutes.companies}>
            Companies
          </Link>
        </MenuItem>
        <MenuItem
          {...(location &&
          (location.pathname === appRoutes.searchCompanies ||
            location.pathname.startsWith(appRoutes.searchCompanies))
            ? { selected: true }
            : {})}
        >
          <Link id="navbarLink_search_companies" to={appRoutes.searchCompanies}>
            Search Companies
          </Link>
        </MenuItem>
      </Menu>
    </CarbonNavigationBar>
  );
};

export default NavigationBar;
