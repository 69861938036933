import { Row, Column } from "carbon-react/lib/components/row";

const TimeTakenForSearch = ({ searchExecutionTime }) => {
  if (
    searchExecutionTime === "" ||
    searchExecutionTime === null ||
    searchExecutionTime === undefined
  )
    return null;
  return (
    <>
      <div className="search-times">
        <div className="invisible-div"></div>
        <div className="directory-label">
          Search Execution Time: {searchExecutionTime} ms
        </div>
      </div>
    </>
  );
};

export default TimeTakenForSearch;
